import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = (): JSX.Element => (
  <Layout>
    {() => (
      <>
        <Seo title="404 Not found" />
        <div className="container mx-auto html">
          <h1>Page not found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </>
    )}
  </Layout>
)

export default NotFoundPage
